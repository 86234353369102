.sticky{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6) !important;
    position: fixed !important;
    top: 0 !important;
    width: 100%;
    transition: .7s;
    z-index: 100 !important;
}
.pt-15{
    padding-top: 3rem;
    padding-bottom: 1rem;
}
.backimg{
    background-image: url(../public/image/banner-inside-d.jpg) !important;
}
.pb-1{
    padding-bottom: 2rem;
}
.w-10{
    width: 50rem;
}
.h-10{
    height: 10rem;
}
.aligh-center{
    text-align: center;
}